<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-card>
      <!-- Header: company Info -->
      <div class="d-flex mb-1">
        <feather-icon
          icon="DownloadIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Rapportage aanmaken
        </h4>
      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          ref="createReportForm"
          @submit.prevent="handleSubmit(createNewReport)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="periodFrom"
                rules="required|date"
              >
                <b-form-group
                  label="Periode van"
                  label-for="periodFrom"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="periodFrom"
                    v-model="createReport.periodFrom"
                    :config="periodFromConfig"
                    :state="getValidationState(validationContext)"
                  >
                  </flat-pickr>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="periodTo"
                rules="required|date"
              >
                <b-form-group
                  label="Periode tot"
                  label-for="periodTo"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="idExpiryDate"
                    v-model="createReport.periodTo"
                    :config="periodToConfig"
                    :state="getValidationState(validationContext)"
                  >
                  </flat-pickr>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="createReport.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="active"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                type="submit"
              >
                <b-spinner
                  small
                  class="mr-1"
                  v-if="createReportSpinner"
                />
                Aanmaken
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="refresh()"
              >
                <span class="text-nowrap">Refresh</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReportsListTable"
        class="position-relative"
        :items="fetchReports"
        responsive
        :fields="tableReportsColumns"
        primary-key="id"
        show-empty
        empty-text="Geen overeenkomende records gevonden"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>
        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveType(data.value) }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveStatus(data.value) }}</span>
          </div>
        </template>
        <template #cell(exportLocation)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveLocation(data.value) }}</span>
          </div>
        </template>
        <template #cell(periodFrom)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDate(data.value) }}</span>
          </div>
        </template>
        <template #cell(periodTo)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDate(data.value) }}</span>
          </div>
        </template>
        <template #cell(timestamp)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link
              v-if="data.item.status !== 4 && data.item.status !== 5"
            >
              <b-spinner
                class="cursor-pointer mx-1"
                small
              />
            </b-link>
            <b-link
              v-if="data.item.fileURL"
              :href="data.item.fileURL"
              target="_blank"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-download-icon`"
                class="cursor-pointer mx-1"
                icon="DownloadIcon"
                size="16"
                style="color:#fdb813 "
              />
              <b-tooltip
                :target="`invoice-row-${data.item.id}-download-icon`"
                placement="top"
              >
                <p class="mb-0">
                  Downloaden
                </p>
              </b-tooltip>
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{dataMeta.of}} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalReports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { onUnmounted, ref } from '@vue/composition-api'
import useExportList from '@/views/pages/export/useExportList'
import { formatDate, formatDateTime } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { date, required } from '@core/utils/validations/validations'
import exportStoreModule from '@/views/pages/export/exportStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BForm,
    BButton,
    BTooltip,
    BSpinner,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      periodFromConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      periodToConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      createReportSpinner: false,
      required,
      date,
    }
  },
  methods: {
    createNewReport() {
      this.createReportSpinner = true
      store.dispatch('app-data-export/createReport', this.createReport)
        .then(() => {
          this.createReportSpinner = false
          this.resetForm()
          this.autoRefresh()
          this.$swal({
            icon: 'success',
            title: 'Uw rapportageaanvraag is in de wachtrij geplaatst',
            text: '',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.createReportSpinner = false
          this.$swal({
            icon: 'error',
            title: 'Aanmaken rapportage',
            text: '',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    refresh() {
      this.refetchData()
    },
    autoRefresh() {
      setInterval(() => {
        this.refetchData()
      }, 60000)
    },
  },
  destroyed() {
    clearInterval(this.autoRefresh)
  },
  setup() {
    const REPORTS_APP_STORE_MODULE_NAME = 'app-data-export'

    const blankCreateReport = {
      periodFrom: null,
      periodTo: null,
      type: null,
    }

    const createReport = ref(JSON.parse(JSON.stringify(blankCreateReport)))
    const resetCreateReport = () => {
      createReport.value = JSON.parse(JSON.stringify(blankCreateReport))
    }

    // Register module
    if (!store.hasModule(REPORTS_APP_STORE_MODULE_NAME)) store.registerModule(REPORTS_APP_STORE_MODULE_NAME, exportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCreateReport)

    const {
      fetchReports,
      tableReportsColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refReportsListTable,
      refetchData,
      showOverlay,
      resolveType,
      resolveStatus,
      resolveLocation,
      totalReports,
      typeOptions,
    } = useExportList()

    return {
      fetchReports,
      tableReportsColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refReportsListTable,
      refetchData,
      showOverlay,
      resolveType,
      resolveStatus,
      resolveLocation,
      totalReports,
      formatDate,
      formatDateTime,
      refFormObserver,
      getValidationState,
      resetForm,
      typeOptions,
      createReport,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
